var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("Fonte de dados - Inteligência"),
      ]),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: "Pesquisar...",
                  dense: "",
                  "single-line": "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c("v-spacer"),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "ma-2",
                  attrs: {
                    loading: _vm.loading,
                    disabled: _vm.loading,
                    color: "success",
                  },
                  on: { click: _vm.startIndex },
                  scopedSlots: _vm._u([
                    {
                      key: "loader",
                      fn: function () {
                        return [
                          _c(
                            "span",
                            { staticClass: "custom-loader" },
                            [
                              _c("v-icon", { attrs: { light: "" } }, [
                                _vm._v("mdi-cached"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_vm._v(" Atualizar ")]
              ),
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.indexProcess,
              search: _vm.search,
              "footer-props": {
                "items-per-page-options": [10, 25, 50],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.statusProcess",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.statusProcess) + " ")]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }