<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">Fonte de dados - Inteligência</h2>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar..."
          dense
          single-line
          hide-details
        ></v-text-field>
        <v-spacer />
        <v-spacer />
        <v-btn class="ma-2" :loading="loading" :disabled="loading" color="success" @click="startIndex">
          Atualizar
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="indexProcess"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
      >
        <template v-slot:item.statusProcess="{ item }">
          {{ item.statusProcess }}
          <!--<slot name="statusProcess">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind:class="{
                    blink: item.statusProcess.includes('Start Index') || item.statusProcess.includes('Indexando'),
                  }"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.statusProcess }}
                </v-chip>
              </template>
              <span>{{ item.errorCause }}</span>
            </v-tooltip>
          </slot>-->
        </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>

<script>
import 'moment/locale/pt-br';
import indexService from '@/services/indexService.js';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      search: '',
      error: false,
      headers: [
        {
          text: 'Qtd. de registro indexado',
          align: 'start',
          sortable: false,
          value: 'amount',
        },
        { text: 'Data', value: 'date' },
        { text: 'Hora', value: 'hour' },
        { text: 'Usuário', value: 'userLogged' },
        { text: 'Situação do processo', value: 'statusProcess' },
      ],

      indexProcess: [],
      loader: null,
      loading: false,
      indexing: false,
      finishSetInterval: true,
    };
  },

  watch: {},

  mounted() {
    this.getAll();
    this.getAllInterval();
  },

  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },

  methods: {
    async getAll() {
      this.error = false;
      try {
        const response = await indexService.indexProcess.getAllByUser(this.userInfo.username);
        //const response = await indexService.indexProcess.getAll();
        this.loading = false;
        this.indexProcess = response.data.content;
        if (
          this.indexProcess[0].statusProcess.includes('Start Index') ||
          this.indexProcess[0].statusProcess.includes('Indexando')
        ) {
          this.indexing = true;
          this.finishSetInterval = false;
          this.indexProcess[0].errorCause = 'Em processo...';
        } else {
          this.indexing = false;
          this.finishSetInterval = true;
        }
        this.indexProcess.map((u) => {
          u.date = moment(u.indexDateTime).format('L');
          u.hour = moment(u.indexDateTime).format('LTS');
          u.errorCause = u.errorCause != null ? u.errorCause : 'Processo finalizado!';
        });
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },

    async startIndex() {
      this.finishSetInterval = false;
      this.loading = true;
      this.error = false;
      try {
        await indexService.indexProcess.startIndex();
        this.getAllInterval();
      } catch (error) {
        this.loading = false;
        this.indexing = false;
        this.finishSetInterval = false;
        this.error = true;
      }
    },

    getAllInterval() {
      let myInterval = setInterval(() => {
        this.getAll();
        if (this.finishSetInterval) {
          clearInterval(myInterval);
        }
      }, 2000);
    },
  },
};
</script>

<style scoped>
.mt {
  margin-top: 100px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes blink-effect {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
.blink {
  -webkit-animation: blink-effect 0.75s linear infinite;
  -moz-animation: blink-effect 0.75s linear infinite;
  -ms-animation: blink-effect 0.75s linear infinite;
  -o-animation: blink-effect 0.75s linear infinite;
  animation: blink-effect 0.75s linear infinite;
}
</style>
